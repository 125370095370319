<template>

  <ContentWrapper>

    <div class="container animated-fast fadeInUp"  :class="loading ? 'loading-spinner' : ''">
        <div class="row" v-if="post">
            <div class="col-12">
                <div class="card border-0 overflow-hidden animated-fast fadeInUp">
                    <div class="card-image">
                        <img class="mw-100" :src="post.banner_url + '?width=1920&height=600'" alt="News Banner"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="post">
            <div class="col-xxl-12">
                <div class="card my-3" v-if="post.stream_url">
                    <div class="card-body animated-fast fadeInUp p-0">
                        <div class="video-container">
                            <iframe
                                :src="post.stream_url"
                                loading="lazy"
                                style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                allowfullscreen="true"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div class="card my-3" v-if="post.youtube_id">
                    <div class="card-body animated-fast fadeInUp p-0">
                      <div class="video-container">
                        <iframe width="560" height="315" :src="'https://www.youtube-nocookie.com/embed/' + post.youtube_id" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                    </div>
                </div>
                <div class="card mt-5">
                    <div class="card-body pt-5 animated-fast fadeInUp">
                        <small class="float-end text-muted">Gepostet am {{ $moment.utc(post.published_at).local().format('Do MMMM') }}</small>
                        <h1>{{ post.title }}</h1>
                        <div class="mt-5">
                            <markdown-parser :source="post.content"></markdown-parser>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ContentWrapper>
</template>
<style lang="scss">

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>
<script>
import MarkdownParser from "@/components/Common/MarkdownParser.vue";

export default {
    components: {MarkdownParser},
    data() {
        return {
            post: null,
            loading: true
        }
    },
    beforeCreate() {
        this.$api.get('news/post/' + this.$route.params.id + '?language=' + this.$i18n.locale).then(response => {
            if (response.data.redirect_url) {
                window.open(response.data.redirect_url, '_blank');
                this.$router.push({name: 'News:Posts'});
            } else {
                this.post = response.data;
                this.loading = false;
            }
        });

    }
}
</script>
